import { Application } from '@hotwired/stimulus'

const genericControllers = import.meta.glob(
  '../controllers/**/*_controller.js',
  { eager: true }
)

const componentControllers = import.meta.glob(
  './../../components/**/controller.js',
  { eager: true }
)

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

// Register generic controllers
for (const path in genericControllers) {
  if (Object.prototype.hasOwnProperty.call(genericControllers, path)) {
    const module = genericControllers[path]
    const name = path
      .match(/(.+)_controller\.js$/)[1]
      .replaceAll('/', '-')
      .replaceAll('_', '-')

    application.register(name, module.default)
  }
}

// Register component controllers
for (const path in componentControllers) {
  if (Object.prototype.hasOwnProperty.call(componentControllers, path)) {
    const module = componentControllers[path]
    const name = path
      .match(/components\/(.+)\/controller\.js$/)[1]
      .replaceAll('/', '-')
      .replaceAll('_', '-')

    application.register(name, module.default)
  }
}

export default application
