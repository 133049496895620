import ApplicationController from './application'

export default class extends ApplicationController {

  static classes = []

  static outlets = []

  static targets = [
    'toggleButton'
  ]

  static values = {}

  initialize() {}

  connect() {
    this.#loadThemePreference()
  }

  // ==== Actions

  toggle() {
    const isDarkMode = document.documentElement.classList.toggle('dark')
    this.#saveThemePreference(isDarkMode)
  }

  // ==== Callbacks

  // ==== Private

  #loadThemePreference() {
    const isDarkMode = localStorage.getItem('themePreference') === 'true'
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    }
  }

  #saveThemePreference(isDarkMode) {
    localStorage.setItem('themePreference', isDarkMode)
  }

}

// import { Controller } from '@hotwired/stimulus'

// export default class extends Controller {
//   static targets = ['toggleButton'];

//   connect() {
//     this.loadThemePreference();
//   }

//   toggle() {
//     const isDarkMode = document.documentElement.classList.toggle("dark");
//     this.saveThemePreference(isDarkMode);
//   }

//   loadThemePreference() {
//     const isDarkMode = localStorage.getItem("themePreference") === "true";
//     if (isDarkMode) {
//       document.documentElement.classList.add("dark");
//     }
//   }

//   saveThemePreference(isDarkMode) {
//     localStorage.setItem("themePreference", isDarkMode);
//   }
// }
